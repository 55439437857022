import template from './news.html';
import controller from './news.controller';
import './news.scss';
import './news-scroll.scss';

const newsComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout', 'newsModel'];

export default newsComponent;
