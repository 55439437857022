import angular from 'angular';
import hebcal from 'hebcal';

const hebDateModule = angular.module('hebDate', [])
  .filter('hebDate', function () {
    return function (date) {
      return hebcal.HDate(new Date(date)).toString('h');
    };
  })
  .name;

export default hebDateModule;
