import template from './log-in.html';
import controller from './log-in.controller';
import './log-in.scss';

const logInComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$state', 'logInService'];

export default logInComponent;
