import template from './manage-tapes.html';
import controller from './manage-tapes.controller';
import './manage-tapes.scss';

const manageTapesComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout', 'tapeModel', 'FileUploader', 'googleAnalytics'];

export default manageTapesComponent;
