import angular from 'angular';

import GLOBAL_SETTINGS from '../../global-settings';
const URL = GLOBAL_SETTINGS.HOST + (GLOBAL_SETTINGS.PORT ? ':' + GLOBAL_SETTINGS.PORT : '');

let $http;

class userModelService {
  constructor (_$http) {
    $http = _$http;
  }

  list () {
    return $http({
      method: 'GET',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/user/'
    });
  }

  find (id) {
    return $http({
      method: 'GET',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/user/' + id
    });
  }

  add (user) {
    return $http({
      method: 'POST',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/user/',
      data: { user }
    });
  }

  edit (user) {
    return $http({
      method: 'PUT',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/user/' + user.id,
      data: { user }
    });
  }

  delete (userId) {
    return $http({
      method: 'DELETE',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/user/' + userId,
      data: { delete: 'delete' }
    });
  }
}

userModelService.$inject = ['$http'];

const userModelModule = angular.module('userModel', [])
  .service('userModel', userModelService)
  .name;

export default userModelModule;
