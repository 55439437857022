let $timeout;

class TapesEditController {
  constructor ($rootScope, $state, _$timeout, tapeModel, tapeCategoryModel) {
    const vm = this;
    vm.$rootScope = $rootScope;
    vm.$state = $state;
    $timeout = _$timeout;
    vm.tapeModel = tapeModel;
    vm.tapeCategoryModel = tapeCategoryModel;

    vm.initTapesCategories();
  }

  $onInit () {
    const vm = this;

    vm.tape = vm.tape || {};

    vm.modelObject = {
      id: vm.tape.id || null,
      name: vm.tape.name,
      type: vm.tape.type,
      year: vm.tape.year,
      length: 1,
      description: vm.tape.description,
      tapeCategoryId: vm.tape.tapeCategoryId || null,
      isAvailable: (angular.isUndefined(vm.tape.isAvailable) ? true : vm.tape.isAvailable),
      note: vm.tape.note
    };
  }

  initTapesCategories () {
    const vm = this;
    vm.tapeCategoryModel.list()
      .then((response) => {
        vm.tapesCategoriesList = response.data.data;
      });
  }

  save () {
    const vm = this;
    if (vm.tapesEditForm.$invalid) {
      vm.tapesEditForm.name.$setDirty();
      vm.tapesEditForm.price.$setDirty();
      return console.log('Form is invalid.');
    }

    return vm.tapeModel[vm.tape.id ? 'edit' : 'add'](vm.modelObject)
      .then((res) => {
        vm.$rootScope.$broadcast('reload tapes');
        $timeout(() => {
          vm.close();
        }, 100);
      });
  }

  close () {
    this.$rootScope.$broadcast('modalBoxClose');
  }

  delete () {
    const vm = this;
    if (confirm('האם למחוק קלטת?')) {
      return vm.tapeModel.delete(vm.modelObject.id)
        .then((res) => {
          vm.$rootScope.$broadcast('reload tapes');
          vm.close();
        });
    }
  }
}

export default TapesEditController;
