import template from './books-edit.html';
import controller from './books-edit.controller';
import './books-edit.scss';

const booksEditComponent = {
  bindings: {
    book: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$state', '$timeout', 'bookModel'];

export default booksEditComponent;
