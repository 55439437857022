import template from './manage-orders.html';
import controller from './manage-orders.controller';
import './manage-orders.scss';

const manageOrdersComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'orderModel', 'googleAnalytics'];

export default manageOrdersComponent;
