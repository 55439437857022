import template from './order-manage.html';
import controller from './order-manage.controller';
import './order-manage.scss';

const orderManageComponent = {
  bindings: {
    order: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$state', '$timeout', 'orderModel'];

export default orderManageComponent;
