import template from './manage.html';
import controller from './manage.controller';
import './manage.scss';

const manageComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['googleAnalytics'];

export default manageComponent;
