function manageUser ($scope, userModel) {
  let vm;
  class ManageUserController {
    constructor () {
      vm = this;
      vm.loading = true;
      vm.userListColumns = [
        {
          name: 'number',
          label: 'מזהה'
        },
        {
          name: 'createdAt',
          label: 'תאריך יצירה',
          filter: 'date'
        },
        {
          name: 'firstName',
          label: 'שם פרטי'
        },
        {
          name: 'lastName',
          label: 'שם משפחה'
        },
        {
          name: 'email',
          label: 'דואר אלקטרוני'
        },
        {
          name: 'permission',
          label: 'הרשאה'
        },
        {
          name: 'active',
          label: 'פעיל',
          filter: 'boolean'
        },
        {
          name: 'editPassword',
          label: '',
          filter: 'button'
        }
      ];

      vm.buttons = [
        {
          label: 'משתמש חדש',
          click: vm.newUser
        }
      ];

      vm.initUserList();
      $scope.$on('reload users', () => {
        vm.initUserList();
      });
    }

    initUserList () {
      userModel.list()
        .then((res) => {
          vm.userList = res.data.data;
          vm.userList.forEach((user) => {
            user.editPassword = {
              text: 'שינוי סיסמה',
              click: ($event) => { vm.editUserPassword(user); $event.stopPropagation(); }
            };
          });
          vm.loading = false;
        });
    }

    clickOnRow (user) {
      vm.editUser(user);
    }

    newUser () {
      vm.user = {};
      vm.editPassword = false;
      vm.userModalBoxHeader = 'משתמש חדש';
      vm.userModalBoxIsOpen = true;
    }

    editUser (user) {
      vm.user = user;
      vm.editPassword = false;
      vm.userModalBoxHeader = 'עריכת משתמש';
      vm.userModalBoxIsOpen = true;
    }

    editUserPassword (user) {
      vm.user = user;
      vm.editPassword = true;
      vm.userModalBoxHeader = 'שינוי סיסמה';
      vm.userModalBoxIsOpen = true;
    }
  }
  return new ManageUserController();
}
export default manageUser;
