import template from './tapes.html';
import controller from './tapes.controller';
import './tapes.scss';

const tapesComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', 'tapeModel'];

export default tapesComponent;
