import template from './order-customer.html';
import controller from './order-customer.controller';
import './order-customer.scss';

const orderCustomerComponent = {
  require: {
    orderComponent: '^order'
  },
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', 'utils'];

export default orderCustomerComponent;
