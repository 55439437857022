import template from './book-show.html';
import controller from './book-show.controller';
import './book-show.scss';

const bookShowComponent = {
  bindings: {
    book: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$state', '$sce', 'bookModel'];

export default bookShowComponent;
