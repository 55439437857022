import angular from 'angular';

import GLOBAL_SETTINGS from '../../global-settings';
const URL = GLOBAL_SETTINGS.HOST + (GLOBAL_SETTINGS.PORT ? ':' + GLOBAL_SETTINGS.PORT : '');

let $http;

class logInService {
  constructor ($rootScope, _$http) {
    const vm = this;
    vm.$rootScope = $rootScope;
    $http = _$http;

    vm.$rootScope.isManager = () => {
      return vm.$rootScope.user.permission === 'manager' ||
        vm.$rootScope.user.permission === 'admin';
    };
    vm.$rootScope.isAdmin = () => {
      return vm.$rootScope.user.permission === 'admin';
    };
  }

  logIn (email, password) {
    return $http({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      url: URL + '/api/login',
      data: {
        email,
        password
      }
    })
      .then((res) => {
        return res;
      });
  }

  isAuthenticated () {
    const vm = this;
    return $http({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/is-authenticated',
      withCredentials: true
    })
      .then((res) => {
        if (!vm.$rootScope.user) {
          const user = res.data.data;
          delete user.passwordHash;
          vm.$rootScope.user = user;
        }
        return res;
      });
  }

  logOut () {
    const vm = this;
    return $http({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      url: URL + '/api/logout'
    })
      .then((res) => {
        if (res.data.data === 'disconnected') {
          vm.$rootScope.user = null;
        }
        return res;
      });
  }
}

logInService.$inject = ['$rootScope', '$http'];

const logInModule = angular.module('logInService', [])
  .service('logInService', logInService)
  .name;

export default logInModule;
