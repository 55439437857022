
let $timeout;

class UserEditController {
  constructor ($rootScope, $state, _$timeout, userModel) {
    const vm = this;
    vm.$rootScope = $rootScope;
    vm.$state = $state;
    $timeout = _$timeout;
    vm.userModel = userModel;
  }

  $onInit () {
    const vm = this;

    vm.user = vm.user || {};

    vm.modelObject = {
      id: vm.user.id || null,
      firstName: vm.user.firstName || null,
      lastName: vm.user.lastName || null,
      email: vm.user.email || null,
      permission: vm.user.permission || 'user',
      active: (angular.isUndefined(vm.user.active) ? true : vm.user.active)
    };
  }

  // TODO! move to global help functions.
  setFormDirty (form) {
    angular.forEach(form, (value, key) => {
      if (typeof value === 'object' && value.hasOwnProperty('$modelValue') && value.$invalid) {
        value.$setDirty();
      }
    });
  }

  save () {
    const vm = this;

    if (vm.userEditForm.$invalid) {
      vm.setFormDirty(vm.userEditForm);
      return console.log('Form is invalid.');
    }

    let modelObject;

    if (vm.user.id && vm.editPassword) {
      modelObject = {
        id: vm.modelObject.id,
        password: vm.modelObject.password
      };
    } else {
      modelObject = vm.modelObject;
    }

    return vm.userModel[vm.user.id ? 'edit' : 'add'](modelObject)
      .then((res) => {
        vm.$rootScope.$broadcast('reload users');
        $timeout(() => {
          vm.close();
        }, 100);
      });
  }

  close () {
    this.$rootScope.$broadcast('modalBoxClose');
  }

  delete () {
    const vm = this;
    if (confirm('האם למחוק משתמש?')) {
      return vm.userModel.delete(vm.modelObject.id)
        .then((res) => {
          vm.$rootScope.$broadcast('reload users');
          vm.close();
        });
    }
  }

  comparePassword () {
    const vm = this;
    const matchPasswords = vm.modelObject.password === vm.modelObject.password2;
    vm.userEditForm.password.$setValidity('matchPasswords', matchPasswords);
  }
}

export default UserEditController;
