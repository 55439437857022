import _ from 'lodash';
import Hebcal from 'hebcal';

function newsletters ($scope, newsletterModel, parshaService) {
  let vm;

  class NewslettersController {
    constructor () {
      vm = this;
      vm.loading = true;
      vm.name = 'newsletters';
      vm.label = 'אוצרות בן מלך';

      vm.orderBy = '-number';
      vm.filters = {
        humash: {
          title: 'חומש',
          options: ['בראשית', 'שמות', 'ויקרא', 'במדבר', 'דברים'],
          value: undefined
        },
        parsha: {
          title: 'פרשה',
          options: [],
          value: undefined
        },
        year: {
          title: 'שנה',
          options: [],
          value: undefined
        }
      };
      vm.filters.parsha.options = vm.getParshaFromHumash();
      vm.filters.year.options = getYearOptions();
      vm.initNewsletterList();
      $scope.$on('reload newsletters', () => {
        vm.initNewsletterList();
      });
    }

    sort (value) {
      if (vm.orderBy === value) {
        vm.orderBy = vm.togglePlusMinus(vm.orderBy);
      } else {
        vm.orderBy = value;
      }
    }

    togglePlusMinus (value) {
      if (value[0] === '-') {
        value = value.replace('-', '');
      } else {
        value = '-' + value;
      }
      return value;
    }

    initNewsletterList () {
      newsletterModel.list(true)
        .then((res) => {
          vm.newsletterList = _.get(res, 'data.data');
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    openNewsletter (newsletter) {
      if (!newsletter.fileExist) {
        return;
      }
      vm.newsletterModalBoxHeader = 'אוצרות בן מלך - ' + [newsletter.number, newsletter.parsha, newsletter.year]
        .filter((i) => !!i).join(' ');
      vm.newsletter = newsletter;
      vm.newsletterModalBoxIsOpen = true;
    }

    humashFilterChanged () {
      vm.filters.parsha.options = vm.getParshaFromHumash();
    }

    getParshaFromHumash () {
      if (vm.filters.humash.value) {
        return parshaService.parshaList[vm.filters.humash.value];
      }
      return _.flatMap(parshaService.parshaList);
    }

    parshaFilter (item, b, c) {
      if (vm.filters.parsha.value) {
        return item.parsha.split(' ').find((i) => {
          return vm.filters.parsha.value === i;
        }) || item.parsha === vm.filters.parsha.value;
      }
      if (vm.filters.humash.value && _.get(vm.filters, 'parsha.options.length')) {
        return item.parsha.split(' ').find((i) => {
          return vm.filters.parsha.options.includes(i);
        }) || vm.filters.parsha.options.includes(item.parsha);
      }
      return true;
    }
  }
  return new NewslettersController();
}

export default newsletters;

function getYearOptions () {
  let year = Hebcal.HDate().getFullYear() - 5000;
  const yearOptions = [];
  while (year >= 778) {
    yearOptions.push(Hebcal.gematriya(year));
    year--;
  }
  return yearOptions;
}
