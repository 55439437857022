import GLOBAL_SETTINGS from '../../../global-settings';
const URL = GLOBAL_SETTINGS.HOST + (GLOBAL_SETTINGS.PORT ? ':' + GLOBAL_SETTINGS.PORT : '');

function manageNewsletter ($scope, $timeout, newsletterModel, FileUploader, googleAnalytics) {
  let vm;

  class ManageNewsletterController {
    constructor () {
      vm = this;
      vm.loading = true;
      vm.newsletterListColumns = [
        {
          name: 'number',
          label: 'מספר'
        },
        {
          name: 'parsha',
          label: 'פרשה'
        },
        {
          name: 'year',
          label: 'שנה'
        },
        {
          name: 'isAvailable',
          label: 'זמין באתר',
          filter: 'boolean'
        },
        // {
        //   name: 'note',
        //   label: 'הערות'
        // },
        {
          name: 'fileButton',
          filter: 'button'
        }
      ];

      vm.buttons = [
        {
          label: 'הוספת עלון',
          click: vm.newNewsletter
        }
      ];

      vm.initNewsletterList();
      $scope.$on('reload newsletters', () => {
        vm.initNewsletterList();
      });

      googleAnalytics.setPage('ניהול עלונים', 'manage-newsletters');

      vm.setupFileUploader();
    }

    initNewsletterList () {
      newsletterModel.list()
        .then((response) => {
          vm.newsletterList = response.data.data;
          vm.newsletterList.forEach((item) => {
            if (item.fileExist) {
              item.fileButton = {
                text: '<i class="fa fa-fw fa-trash"></i> מחיקת קובץ',
                click: vm.deleteNewsletterFileButton
              };
            } else {
              item.fileButton = {
                text: '<i class="fa fa-fw fa-upload"></i> העלאת קובץ',
                click: vm.uploadNewsletterFileButton
              };
            }
          });

          vm.loading = false;
        });
    }

    clickOnRow (newsletter) {
      vm.editNewsletter(newsletter);
    }

    newNewsletter () {
      vm.newsletter = {};
      vm.newsletterModalBoxHeader = 'הוספת עלון';
      vm.newsletterModalBoxIsOpen = true;
    }

    editNewsletter (newsletter) {
      vm.newsletter = newsletter;
      vm.newsletterModalBoxHeader = 'עריכת עלון';
      vm.newsletterModalBoxIsOpen = true;
    }

    deleteNewsletterFileButton (newsletter, event) {
      if (confirm('האם למחוק קובץ עלון?')) {
        newsletterModel.deleteFile(newsletter.id)
          .then((response) => {
            console.log('response:', response);
            vm.initNewsletterList();
          });
      }
    }

    uploadNewsletterFileButton (newsletter, event) {
      vm.newsletter = newsletter;
      vm.newsletterFileModalBoxHeader = 'העלאת קובץ עלון';
      vm.uploaderOptions.url = URL + '/api/newsletter/' + newsletter.id + '/upload';
      vm.newsletterFileModalBoxIsOpen = true;
    }

    setupFileUploader () {
      vm.uploader = new FileUploader();
      vm.uploaderOptions = {
        url: '',
        queueLimit: 1
      };
      vm.uploader.onAfterAddingFile = function (item) {
        vm.uploader.queue = [vm.uploader.queue[vm.uploader.queue.length - 1]];
        vm.fileUploadProgress = 0;
      };
      vm.uploader.onBeforeUploadItem = function (item) {
        vm.fileUploadProgress = 0;
      };
      vm.uploader.onProgressItem = function (item, progress) {
        vm.fileUploadProgress = progress;
      };
      vm.uploader.onSuccessItem = function (item, response, status, headers) {
        vm.uploader.queue = [];
        vm.fileUploadProgress = 0;
        vm.fileUploadMessage = 'הקובץ הועלה בהצלחה';
        console.log('response:', response);
        vm.initNewsletterList();

        $timeout(() => {
          vm.fileUploadMessage = '';
          vm.newsletterFileModalBoxIsOpen = false;
        }, 1000);
      };
      vm.uploader.onErrorItem = function (item, response, status, headers) {
        vm.uploader.queue = [];
        vm.fileUploadProgress = 0;
        vm.fileUploadMessage = 'שגיאה בהעלאת קובץ';
        $timeout(() => {
          vm.fileUploadMessage = '';
          vm.newsletterFileModalBoxIsOpen = false;
        }, 2000);
      };
    }
  }
  return new ManageNewsletterController();
}

export default manageNewsletter;
