class LogInController {
  constructor ($state, logInService) {
    const vm = this;
    vm.$state = $state;
    vm.logInService = logInService;
  }

  logIn () {
    const vm = this;
    const email = vm.email;
    const password = vm.password;
    vm.errorMessage = '';
    vm.loginProgress = true;

    vm.logInService.logIn(email, password)
      .then((res) => {
        console.log(res.data);
        vm.loginProgress = false;

        if (res) {
          this.$state.transitionTo('manage');
        }
      })
      .catch((error) => {
        const errorMessage = error.data.data.message;
        switch (errorMessage) {
          case 'Invalid password':
            vm.errorMessage = 'סיסמה שגויה';
            break;
          case 'Unknown user':
            vm.errorMessage = 'משתמש לא קיים';
            break;
          case 'Missing credentials':
            vm.errorMessage = 'פרטים חסרים';
            break;
          default:
            vm.errorMessage = 'שגיאה';
            break;
        }

        vm.loginProgress = false;
        return error;
      });
  }
}

export default LogInController;
