import angular from 'angular';
import User from './user/user';
import modalBox from './modal-box/modal-box.directive';
import checkbox from './checkbox/checkbox';
import page from './page/page';
import list from './list/list';
import loader from './loader/loader';
import account from './account/account';
import hebDate from './filter/hebcal.filter';

const commonModule = angular.module('app.common', [
  User,
  modalBox,
  checkbox,
  page,
  list,
  loader,
  account,
  hebDate
])
  .name;

export default commonModule;
