import angular from 'angular';

import GLOBAL_SETTINGS from '../../global-settings';
const URL = GLOBAL_SETTINGS.HOST + (GLOBAL_SETTINGS.PORT ? ':' + GLOBAL_SETTINGS.PORT : '');

let $http;

class orderModelService {
  constructor (_$http) {
    $http = _$http;
  }

  list () {
    return $http({
      method: 'GET',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/order/'
    });
  }

  find (id) {
    return $http({
      method: 'GET',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/order/' + id
    });
  }

  add (order) {
    return $http({
      method: 'POST',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/order/',
      data: { order }
    });
  }

  manage (order) {
    return $http({
      method: 'PUT',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/order/' + order.id + '/manage',
      data: { order }
    });
  }

  delete (orderId) {
    return $http({
      method: 'DELETE',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/order/' + orderId,
      data: { delete: 'delete' }
    });
  }
}

orderModelService.$inject = ['$http'];

const orderModelModule = angular.module('orderModel', [])
  .service('orderModel', orderModelService)
  .name;

export default orderModelModule;
