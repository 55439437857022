import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import 'angular-file-upload';
import ngSanitize from 'angular-sanitize';
import appConfig from './app.config';
import jQuery from 'jquery';
import Common from './common/common';
import Components from './components/components';
import Directives from './directives/directives';
import Services from './services/services';
import AppComponent from './app.component';
import 'normalize.css';
import './common/common.scss';

window.jQuery = jQuery;

angular.module('app', [
  uiRouter,
  ngSanitize,
  Common,
  Components,
  Directives,
  Services,
  'angularFileUpload',
  'trumbowyg-ng',
  'pdfjsViewer'
])
  .config(appConfig)
  .component('app', AppComponent)
  .filter('trusted', ['$sce', function ($sce) {
    return function (html) {
      return $sce.trustAsHtml(html);
    };
  }]);

window.setTimeout(() => {
  location.href = 'https://ben-melech.com/';
  location.reload();
}, 1000 * 60 * 60 * 12);
