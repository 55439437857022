import angular from 'angular';
import manageBooksComponent from './manage-books.component';
import BooksEdit from './books-edit/books-edit';

const manageBooksModule = angular.module('manageBooks', [
  BooksEdit
])
  .component('manageBooks', manageBooksComponent)
  .name;

export default manageBooksModule;
