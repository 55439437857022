import template from './manage-newsletters.html';
import controller from './manage-newsletters.controller';
import './manage-newsletters.scss';

const manageNewslettersComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout', 'newsletterModel', 'FileUploader', 'googleAnalytics'];

export default manageNewslettersComponent;
