import angular from 'angular';
import Home from './home/home';
import LogIn from './log-in/log-in';
import Books from './books/books';
import Tapes from './tapes/tapes';
import News from './news/news';
import Newsletters from './newsletters/newsletters';
import Order from './order/order';
import Manage from './manage/manage';
import Admin from './admin/admin';

const componentModule = angular.module('app.components', [
  Home,
  LogIn,
  Books,
  Tapes,
  News,
  Newsletters,
  Order,
  Manage,
  Admin
])
  .name;

export default componentModule;
