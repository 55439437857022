import template from './account.html';
import controller from './account.controller';
import './account.scss';

const accountComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

// controller.$inject = [''];

export default accountComponent;
