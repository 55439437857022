import template from './manage-books.html';
import controller from './manage-books.controller';
import './manage-books.scss';

const manageBooksComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout', 'bookModel', 'FileUploader', 'googleAnalytics'];

export default manageBooksComponent;
