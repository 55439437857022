import angular from 'angular';
import manageComponent from './manage.component';
import ManageBooks from './manage-books/manage-books';
import ManageNews from './manage-news/manage-news';
import ManageNewsletters from './manage-newsletters/manage-newsletters';
import ManageOrders from './manage-orders/manage-orders';
import ManageTapeCategories from './manage-tape-categories/manage-tape-categories';
import ManageTapes from './manage-tapes/manage-tapes';

const manageModule = angular.module('manage', [
  ManageBooks,
  ManageNews,
  ManageNewsletters,
  ManageOrders,
  ManageTapeCategories,
  ManageTapes
])
  .component('manage', manageComponent)
  .name;

export default manageModule;
