import template from './admin.html';
import controller from './admin.controller';
import './admin.scss';

const adminComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

// controller.$inject = [''];

export default adminComponent;
