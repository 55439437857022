import template from './manage-users.html';
import controller from './manage-users.controller';
import './manage-users.scss';

const manageUsersComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', 'userModel'];

export default manageUsersComponent;
