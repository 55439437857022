import angular from 'angular';
import newslettersComponent from './newsletters.component';
import newsletterShow from './newsletter-show/newsletter-show';

const newslettersModule = angular.module('newsletters', [
  newsletterShow
])
  .component('newsletters', newslettersComponent)
  .name;

export default newslettersModule;
