import template from './books.html';
import controller from './books.controller';
import './books.scss';

const booksComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', 'bookModel'];

export default booksComponent;
