function orderDelivery ($scope, utils) {
  let vm;

  class OrderDeliveryController {
    constructor () {
      vm = this;
      vm.name = 'orderDelivery';
      vm.utils = utils;

      vm.fields = [
        {
          name: 'street',
          label: 'רחוב'
        },
        {
          name: 'streetNumber',
          label: 'מספר',
          pattern: '[0-9]{0,3}',
          maxlength: 3
        },
        {
          name: 'house',
          label: 'דירה',
          pattern: '[0-9]{0,3}',
          maxlength: 3
        },
        {
          name: 'city',
          label: 'עיר'
        },
        {
          name: 'pob',
          label: 'ת. דואר'
        }
      ];
    }

    $onInit () {
      if (vm.orderComponent.orderObject.delivery.deliveryName) {
        vm.toggleDeliveryNameCB = true;
      }
    }

    toggleDeliveryName () {
      if (!vm.toggleDeliveryNameCB) {
        vm.orderComponent.orderObject.delivery.deliveryName = '';
      }
    }

    continue () {
      if (vm.deliveryForm.$valid) {
        vm.orderComponent.steps[3].isAvailable = true;
        vm.orderComponent.moveToStep(3);
      } else {
        utils.setFormFieldsDirty(vm.deliveryForm);
      }
    }
  }

  return new OrderDeliveryController();
}

export default orderDelivery;
