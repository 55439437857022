import template from './newsletters-edit.html';
import controller from './newsletters-edit.controller';
import './newsletters-edit.scss';

const newslettersEditComponent = {
  bindings: {
    newsletter: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$state', '$timeout', 'newsletterModel'];

export default newslettersEditComponent;
