import angular from 'angular';
import manageTapesComponent from './manage-tapes.component';
import TapesEdit from './tapes-edit/tapes-edit';

const manageTapesModule = angular.module('manageTapes', [
  TapesEdit
])
  .component('manageTapes', manageTapesComponent)
  .name;

export default manageTapesModule;
