import template from './tapes-edit.html';
import controller from './tapes-edit.controller';
import './tapes-edit.scss';

const tapesEditComponent = {
  bindings: {
    tape: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$state', '$timeout', 'tapeModel', 'tapeCategoryModel'];

export default tapesEditComponent;
