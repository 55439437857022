class NewsletterShowController {
  constructor ($rootScope, $state, $sce, newsletterModel) {
    const vm = this;
    vm.$rootScope = $rootScope;
    vm.$state = $state;
    vm.$sce = $sce;
    vm.newsletterModel = newsletterModel;
  }

  $onInit () {
    const vm = this;

    vm.newsletter = vm.newsletter || {};

    vm.newsletterUrl = vm.$sce.trustAsResourceUrl('//ben-melech.com/media/newsletters/' + vm.newsletter.number + '.pdf');

    // document.getElementsByTagName('embed')[0].src = vm.newsletterUrl;
  }

  close () {
    this.$rootScope.$broadcast('modalBoxClose');
  }
}

export default NewsletterShowController;
