import angular from 'angular';

import GLOBAL_SETTINGS from '../../global-settings';
const URL = GLOBAL_SETTINGS.HOST + (GLOBAL_SETTINGS.PORT ? ':' + GLOBAL_SETTINGS.PORT : '');

let $http;

class newsModelService {
  constructor (_$http) {
    $http = _$http;
  }

  list () {
    return $http({
      method: 'GET',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/news/'
    });
  }

  find (id) {
    return $http({
      method: 'GET',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/news/' + id
    });
  }

  add (news) {
    return $http({
      method: 'POST',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/news/',
      data: { news }
    });
  }

  edit (news) {
    return $http({
      method: 'PUT',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/news/' + news.id,
      data: { news }
    });
  }

  delete (newsId) {
    return $http({
      method: 'DELETE',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/news/' + newsId,
      data: { delete: 'delete' }
    });
  }
}

newsModelService.$inject = ['$http'];

const newsModelModule = angular.module('newsModel', [])
  .service('newsModel', newsModelService)
  .name;

export default newsModelModule;
