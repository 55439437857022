import angular from 'angular';
import manageNewsComponent from './manage-news.component';
import NewsEdit from './news-edit/news-edit';

const manageNewsModule = angular.module('manageNews', [
  NewsEdit
])
  .component('manageNews', manageNewsComponent)
  .name;

export default manageNewsModule;
