import template from './manage-tape-categories.html';
import controller from './manage-tape-categories.controller';
import './manage-tape-categories.scss';

const manageTapeCategoriesComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout', 'tapeCategoryModel', 'googleAnalytics'];

export default manageTapeCategoriesComponent;
