import _ from 'lodash';

function orderPayment ($scope, utils) {
  let vm;

  class OrderPaymentController {
    constructor () {
      vm = this;
      vm.name = 'orderPayment';
      vm.utils = utils;

      vm.fields = [
        {
          name: 'cardNumber',
          label: 'מספר כרטיס אשראי',
          pattern: '[0-9]{0,22}'
        },
        {
          name: 'exp',
          label: 'תוקף'
        },
        {
          name: 'cvv',
          label: 'מספר cvv',
          pattern: '[0-9]{0,4}',
          maxlength: 4
        },
        {
          name: 'numberOfPayments',
          label: 'מספר תשלומים',
          type: 'number',
          max: 24
        }
      ];
    }

    continue () {
      if (vm.paymentForm.$valid) {
        vm.orderComponent.addOrder();
      } else {
        utils.setFormFieldsDirty(vm.paymentForm);
      }
    }
  }

  return new OrderPaymentController();
}

export default orderPayment;
