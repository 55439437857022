import Hebcal from 'hebcal';

function newslettersEdit ($rootScope, $state, $timeout, newsletterModel) {
  let vm;

  class NewslettersEditController {
    constructor () {
      vm = this;
      vm.$rootScope = $rootScope;
      vm.$state = $state;
      vm.newsletterModel = newsletterModel;
      vm.yearOptions = getYearOptions();
    }

    $onInit () {
      const vm = this;

      vm.newsletter = vm.newsletter || {};

      vm.modelObject = {
        id: vm.newsletter.id,
        number: vm.newsletter.number,
        parsha: vm.newsletter.parsha,
        year: vm.newsletter.year,
        isAvailable: (angular.isUndefined(vm.newsletter.isAvailable) ? true : vm.newsletter.isAvailable)
        // note: vm.newsletter.note,
      };
    }

    save () {
      const vm = this;
      if (vm.newslettersEditForm.$invalid) {
        vm.newslettersEditForm.number.$setDirty();
        return console.log('Form is invalid.');
      }

      return vm.newsletterModel[vm.newsletter.id ? 'edit' : 'add'](vm.modelObject)
        .then((res) => {
          vm.$rootScope.$broadcast('reload newsletters');
          $timeout(() => {
            vm.close();
          }, 100);
        });
    }

    close () {
      this.$rootScope.$broadcast('modalBoxClose');
    }

    delete () {
      const vm = this;
      if (confirm('האם למחוק עלון?')) {
        return vm.newsletterModel.delete(vm.modelObject.id)
          .then((res) => {
            vm.$rootScope.$broadcast('reload newsletters');
            vm.close();
          });
      }
    }
  }
  return new NewslettersEditController();
}

export default newslettersEdit;

function getYearOptions () {
  let year = Hebcal.HDate().getFullYear() - 5000;
  const yearOptions = [];
  while (year >= 778) {
    yearOptions.push(Hebcal.gematriya(year));
    year--;
  }
  return yearOptions;
}
