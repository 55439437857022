import _ from 'lodash';

function list () {
  let vm;

  class ListController {
    $onInit () {
      vm = this;
      vm._ = _;
      vm.columnsLength = Object.keys(vm.columns).length;
      vm.orderBy = vm.orderBy || '-createdAt';
    }

    sort (value, doSort) {
      if (doSort === false) {
        return;
      }
      if (vm.orderBy === value) {
        vm.orderBy = vm.togglePlusMinus(vm.orderBy);
      } else {
        vm.orderBy = value;
      }
    }

    togglePlusMinus (value) {
      if (value[0] === '-') {
        value = value.replace('-', '');
      } else {
        value = '-' + value;
      }
      return value;
    }
  }

  return new ListController();
}

export default list;
