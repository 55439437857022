import GLOBAL_SETTINGS from '../../../global-settings';
const URL = GLOBAL_SETTINGS.HOST + (GLOBAL_SETTINGS.PORT ? ':' + GLOBAL_SETTINGS.PORT : '');

function manageTape ($scope, $timeout, tapeModel, FileUploader, googleAnalytics) {
  let vm;
  class ManageTapeController {
    constructor () {
      vm = this;
      vm.loading = true;
      vm.tapeListColumns = [
        {
          name: 'number',
          label: 'מזהה'
        },
        {
          name: 'name',
          label: 'שם'
        },
        {
          name: 'category',
          label: 'קטגוריה'
        },
        {
          name: 'type',
          label: 'סוג'
        },
        {
          name: 'year',
          label: 'שנה'
        },
        {
          name: 'description',
          label: 'תיאור'
        },
        {
          name: 'isAvailable',
          label: 'זמין באתר',
          filter: 'boolean'
        },
        {
          name: 'note',
          label: 'הערות'
        },
        {
          name: 'fileButton',
          filter: 'button'
        }
      ];

      vm.buttons = [
        {
          label: 'הוספת קלטת',
          click: vm.newTape
        }
      ];

      vm.initTapeList();
      $scope.$on('reload tapes', () => {
        vm.initTapeList();
      });

      googleAnalytics.setPage('ניהול קלטות', 'manage-tapes');

      vm.setupFileUploader();
    }

    initTapeList () {
      tapeModel.list()
        .then((response) => {
          vm.tapeList = response.data.data;
          vm.tapeList.forEach((item) => {
            if (item.tapeCategory && item.tapeCategory.name) {
              item.category = item.tapeCategory.name;
            }
            if (item.fileExist) {
              item.fileButton = {
                text: '<i class="fa fa-fw fa-trash"></i> מחיקת קובץ',
                click: vm.deleteTapeFileButton
              };
            } else {
              item.fileButton = {
                text: '<i class="fa fa-fw fa-upload"></i> העלאת קובץ',
                click: vm.uploadTapeFileButton
              };
            }
          });

          vm.loading = false;
        });
    }

    clickOnRow (tape) {
      vm.editTape(tape);
    }

    newTape () {
      vm.tape = {};
      vm.tapeModalBoxHeader = 'הוספת קלטת';
      vm.tapeModalBoxIsOpen = true;
    }

    editTape (tape) {
      vm.tape = tape;
      vm.tapeModalBoxHeader = 'עריכת קלטת';
      vm.tapeModalBoxIsOpen = true;
    }

    deleteTapeFileButton (tape, event) {
      if (confirm('האם למחוק קובץ הקלטה?')) {
        tapeModel.deleteFile(tape.id)
          .then((response) => {
            console.log('response:', response);
            vm.initTapeList();
          });
      }
    }

    uploadTapeFileButton (tape, event) {
      vm.tape = tape;
      vm.tapeFileModalBoxHeader = 'העלאת קובץ הקלטה';
      vm.uploaderOptions.url = URL + '/api/tape/' + tape.id + '/upload';
      vm.tapeFileModalBoxIsOpen = true;
    }

    setupFileUploader () {
      vm.uploader = new FileUploader();
      vm.uploaderOptions = {
        url: '',
        queueLimit: 1
      };
      vm.uploader.onAfterAddingFile = function (item) {
        vm.uploader.queue = [vm.uploader.queue[vm.uploader.queue.length - 1]];
        vm.fileUploadProgress = 0;
      };
      vm.uploader.onBeforeUploadItem = function (item) {
        vm.fileUploadProgress = 0;
      };
      vm.uploader.onProgressItem = function (item, progress) {
        vm.fileUploadProgress = progress;
      };
      vm.uploader.onSuccessItem = function (item, response, status, headers) {
        vm.uploader.queue = [];
        vm.fileUploadProgress = 0;
        vm.fileUploadMessage = 'הקובץ הועלה בהצלחה';
        console.log('response:', response);
        vm.initTapeList();

        $timeout(() => {
          vm.fileUploadMessage = '';
          vm.tapeFileModalBoxIsOpen = false;
        }, 1000);
      };
      vm.uploader.onErrorItem = function (item, response, status, headers) {
        vm.uploader.queue = [];
        vm.fileUploadProgress = 0;
        vm.fileUploadMessage = 'שגיאה בהעלאת קובץ';
        $timeout(() => {
          vm.fileUploadMessage = '';
          vm.tapeFileModalBoxIsOpen = false;
        }, 2000);
      };
    }
  }
  return new ManageTapeController();
}
export default manageTape;
