import angular from 'angular';
import adminComponent from './admin.component';
import ManageUsers from './manage-users/manage-users';

const adminModule = angular.module('admin', [
  ManageUsers
])
  .component('admin', adminComponent)
  .name;

export default adminModule;
