let $timeout;

class OrderManageController {
  constructor ($rootScope, $state, _$timeout, orderModel) {
    const vm = this;
    vm.$rootScope = $rootScope;
    vm.$state = $state;
    $timeout = _$timeout;
    vm.orderModel = orderModel;
  }

  $onInit () {
    const vm = this;

    vm.order = vm.order || {};

    vm.modelObject = {
      id: vm.order.id,
      done: vm.order.done,
      note: vm.order.note
    };
  }

  save () {
    const vm = this;
    if (vm.inProgress) {
      return;
    }

    if (vm.orderManageForm.$invalid) {
      return console.log('Form is invalid.');
    }

    vm.inProgress = true;
    return vm.orderModel.manage(vm.modelObject)
      .then((res) => {
        vm.$rootScope.$broadcast('reload orders');
        vm.inProgress = false;
        $timeout(() => {
          vm.close();
        }, 100);
      })
      .catch((error) => {
        vm.inProgress = false;
        console.log(error);
        alert('שגיאה');
      });
  }

  close () {
    this.$rootScope.$broadcast('modalBoxClose');
  }
}

export default OrderManageController;
