import template from './tapes-categories-edit.html';
import controller from './tapes-categories-edit.controller';
import './tapes-categories-edit.scss';

const tapesCategoriesEditComponent = {
  bindings: {
    tapeCategory: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$state', '$timeout', 'tapeCategoryModel'];

export default tapesCategoriesEditComponent;
