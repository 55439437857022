import _ from 'lodash';

function orderCustomer ($scope, utils) {
  let vm;

  class OrderCustomerController {
    constructor () {
      vm = this;
      vm.name = 'orderCustomer';
      vm.utils = utils;

      vm.fields = [
        {
          name: 'firstName',
          label: 'שם פרטי'
        },
        {
          name: 'lastName',
          label: 'שם משפחה',
          required: true
        },
        {
          name: 'phone',
          label: 'טלפון',
          pattern: '[0-9]{0,11}',
          minlength: 7,
          maxlength: 11,
          onChange: vm.checkPhoneRequired
        },
        {
          name: 'phone2',
          label: 'טלפון נוסף',
          pattern: '[0-9]{0,11}',
          minlength: 7,
          maxlength: 11,
          onChange: vm.checkPhoneRequired
        },
        {
          name: 'email',
          label: 'דואר אלקטרוני',
          type: 'email'
        }
      ];
    }

    checkPhoneRequired () {
      if (!vm.customerForm.phone) {
        return;
      }

      const isNotEmpty = !_.isEmpty(_.trim(_.get(vm.orderComponent, 'orderObject.customer.phone')) || _.trim(_.get(vm.orderComponent, 'orderObject.customer.phone2')));
      if (_.get(vm, 'customerForm.phone2.$dirty')) {
        vm.customerForm.phone.$setDirty();
      }
      vm.customerForm.phone.$setValidity('phoneIsRequired', isNotEmpty);
    }

    continue () {
      if (vm.customerForm.$valid) {
        vm.orderComponent.steps[2].isAvailable = true;
        vm.orderComponent.moveToStep(2);
      } else {
        utils.setFormFieldsDirty(vm.customerForm);
      }
    }
  }

  return new OrderCustomerController();
}

export default orderCustomer;
