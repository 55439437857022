class HomeController {
  constructor ($rootScope, $state, logInService, googleAnalytics) {
    const vm = this;
    vm.$rootScope = $rootScope;
    vm.$state = $state;
    vm.logInService = logInService;
    vm.googleAnalytics = googleAnalytics;

    vm.links = [[
      {
        label: 'אוצרות בן מלך',
        state: 'newsletters'
      },
      {
        label: 'אוצר הספרים',
        state: 'books'
      }
    ], [
      {
        label: 'חדשות בן מלך',
        state: 'news'
      },
      {
        label: 'אוצר הקלטות',
        state: 'tapes'
      }
    ]];
  }

  $onInit () {
    const vm = this;
    vm.googleAnalytics.setPage('', '');

    vm.logInService.isAuthenticated()
      .then((res) => {
        if (res.data.status === 'success') {
          const user = res.data.data;
          vm.$rootScope.user = user;
        }
      });
  }
}

export default HomeController;
