function books ($scope, bookModel) {
  let vm;
  class BooksController {
    constructor () {
      vm = this;
      vm.loading = true;
      vm.name = 'books';
      vm.label = 'אוצר הספרים';

      vm.orderBy = 'name';
      vm.initBookList();
      $scope.$on('reload books', () => {
        vm.initBookList();
      });
    }

    sort (value) {
      if (vm.orderBy === value) {
        vm.orderBy = vm.togglePlusMinus(vm.orderBy);
      } else {
        vm.orderBy = value;
      }
    }

    togglePlusMinus (value) {
      if (value[0] === '-') {
        value = value.replace('-', '');
      } else {
        value = '-' + value;
      }
      return value;
    }

    initBookList () {
      bookModel.list(true)
        .then((res) => {
          vm.bookList = res.data.data;
          vm.loading = false;
        });
    }

    openBook (book) {
      if (!book.fileExist) {
        return;
      }
      vm.bookModalBoxHeader = book.name;
      vm.book = book;
      vm.bookModalBoxIsOpen = true;
    }

    openOrder (book) {
      if (!book.forSale) {
        return;
      }

      vm.book = book;
      vm.orderModalBoxHeader = 'הזמנה חדשה - ' + book.name;
      vm.orderModalBoxIsOpen = true;
    }
  }
  return new BooksController();
}
export default books;
