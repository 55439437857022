import angular from 'angular';
import booksComponent from './books.component';
import bookShow from './book-show/book-show';

const booksModule = angular.module('books', [
  bookShow
])
  .component('books', booksComponent)
  .name;

export default booksModule;
