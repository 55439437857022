import angular from 'angular';

import bookModel from './book-model/book-model.service';
import googleAnalytics from './google-analytics/google-analytics.service';
import logInService from './log-in/log-in.service';
import newsModel from './news-model/news-model.service';
import newsletterModel from './newsletter-model/newsletter-model.service';
import orderModel from './order-model/order-model.service';
import parshaService from './parsha/parsha.service';
import tapeCategoryModel from './tape-category-model/tape-category-model.service';
import tapeModel from './tape-model/tape-model.service';
import userModel from './user-model/user-model.service';
import utils from './utils/utils.service';

const servicesModule = angular.module('app.services', [
  bookModel,
  googleAnalytics,
  logInService,
  newsModel,
  newsletterModel,
  orderModel,
  parshaService,
  tapeCategoryModel,
  tapeModel,
  userModel,
  utils
])
  .name;

export default servicesModule;
