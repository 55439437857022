import template from './page.html';
import controller from './page.controller';
import './page.scss';

const pageComponent = {
  bindings: {
    page: '<'
  },
  transclude: true,
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['googleAnalytics'];

export default pageComponent;
