export default ['$locationProvider', '$stateProvider', '$urlRouterProvider', function ($locationProvider, $stateProvider, $urlRouterProvider) {
  // @see: https://github.com/angular-ui/ui-router/wiki/Frequently-Asked-Questions
  // #how-to-configure-your-server-to-work-with-html5mode
  // $locationProvider.html5Mode(true).hashPrefix('!');
  $locationProvider.hashPrefix('');
  $stateProvider
    .state('logIn', {
      url: '/login',
      resolve: {
        user: ['$state', 'logInService', checkLogin]
      },
      component: 'logIn'
    })
    .state('logOut', {
      url: '/logout',
      resolve: {
        logout: ['$state', 'logInService', logout]
      }
    })
    .state('home', {
      url: '/',
      component: 'home'
    })
    .state('books', {
      url: '/books',
      component: 'books'
    })
    .state('tapes', {
      url: '/tapes',
      component: 'tapes'
    })
    .state('news', {
      url: '/news',
      component: 'news'
    })
    .state('newsletters', {
      url: '/newsletters',
      component: 'newsletters'
    })
    .state('manage', {
      url: '/manage',
      component: 'manage',
      resolve: {
        user: ['$state', 'logInService', managerAuth]
      }
    })
    .state('manage.manageBooks', {
      url: '/manage-books',
      component: 'manageBooks'
    })
    .state('manage.manageTapes', {
      url: '/manage-tapes',
      component: 'manageTapes'
    })
    .state('manage.manageTapeCategories', {
      url: '/manage-tape-categories',
      component: 'manageTapeCategories'
    })
    .state('manage.manageNews', {
      url: '/manage-news',
      component: 'manageNews'
    })
    .state('manage.manageNewsletters', {
      url: '/manage-newsletters',
      component: 'manageNewsletters'
    })
    .state('manage.manageOrders', {
      url: '/manage-orders',
      component: 'manageOrders'
    })
    .state('admin', {
      url: '/admin',
      component: 'admin',
      resolve: {
        user: ['$state', 'logInService', adminAuth]
      }
    })
    .state('admin.manageUsers', {
      url: '/manage-users',
      component: 'manageUsers'
    });

  $urlRouterProvider.otherwise('/');
}];

const userAuth = ($state, logInService, permission) => {
  return logInService.isAuthenticated()
    .then((res) => {
      if (res.data.status === 'success') {
        const user = res.data.data;
        if (permission &&
          user.permission !== permission &&
          permission.indexOf(user.permission) < 0) {
          return $state.transitionTo('home');
        }
        return user;
      } else {
        $state.transitionTo('logIn');
      }
    })
    .catch(() => {
      $state.transitionTo('logIn');
    });
};

const managerAuth = ($state, logInService) => {
  return userAuth($state, logInService, ['admin', 'manager']);
};

const adminAuth = ($state, logInService) => {
  return userAuth($state, logInService, 'admin');
};

const checkLogin = ($state, logInService) => {
  return logInService.isAuthenticated()
    .then((data) => {
      $state.transitionTo('manage');
    })
    .catch((e) => {
      return true;
    });
};

const logout = ($state, logInService) => {
  logInService.logOut()
    .then((resLogout) => {
      $state.transitionTo('home');
    });
};
