import _ from 'lodash';

function order ($scope, $rootScope, $compile, $timeout, orderModel) {
  let vm;

  class OrderController {
    constructor () {
      vm = this;
      vm.name = 'order';
      vm.loading = true;

      vm.steps = [
        {
          label: 'פרטי הזמנה',
          isAvailable: true
        },
        {
          label: 'פרטי המזמין'
        },
        {
          label: 'כתובת למשלוח'
        },
        {
          label: 'אמצעי תשלום'
        }
      ];
      vm.step = 0;
    }

    $onInit () {
      vm.orderObject = {
        product: {
          id: vm.product.id,
          price: vm.product.price
        },
        customer: {
          userId: null,
          firstName: '',
          lastName: '',
          phone: '',
          phone2: '',
          email: ''
        },
        delivery: {
          street: '',
          streetNumber: null,
          house: '',
          city: '',
          pob: ''
        },
        payment: {
          cardNumber: '',
          expMonth: '',
          expYear: '',
          cvv: '',
          numberOfPayments: 1
        }
      };

      vm.moveToStep(0);
      vm.loading = false;
    }

    moveToStep (stepNumber) {
      if (!vm.steps[stepNumber].isAvailable) {
        return;
      }
      vm.step = stepNumber;
    }

    addOrder () {
      if (vm.inProgress) {
        return;
      }

      vm.inProgress = true;
      orderModel.add(vm.orderObject)
        .then((result) => {
          vm.inProgress = false;
          const orderNumber = _.get(result, 'data.data.number');
          console.log(result);

          vm.message = `הזמנה נקלטה בהצלחה
          <br>
          הזמנה מספר ${orderNumber}`;

          vm.messageModalBoxIsOpen = true;
          $timeout(() => {
            // vm.closeModalBox()
          }, 1000);
        })
        .catch((error) => {
          vm.inProgress = false;
          console.log(error);

          vm.message = `שגיאה בקליטת ההזמנה
          <br>
          נא פנה לאחראי`;

          vm.messageModalBoxIsOpen = true;
          $timeout(() => {
            // vm.closeModalBox()
          }, 1000);
        });
    }

    closeOrder () {
      if (confirm('האם לבטל את ההזמנה?')) {
        vm.closeModalBox();
      }
    }

    closeModalBox () {
      $rootScope.$broadcast('modalBoxClose');
    }
  }

  return new OrderController();
}

export default order;
