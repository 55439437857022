import angular from 'angular';

import GLOBAL_SETTINGS from '../../global-settings';
const URL = GLOBAL_SETTINGS.HOST + (GLOBAL_SETTINGS.PORT ? ':' + GLOBAL_SETTINGS.PORT : '');

let $http;

class newsletterModelService {
  constructor (_$http) {
    $http = _$http;
  }

  list (availableOnly) {
    return $http({
      method: 'GET',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/newsletter/' + (availableOnly ? '?availableOnly=true' : '')
    });
  }

  find (id) {
    return $http({
      method: 'GET',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/newsletter/' + id
    });
  }

  add (newsletter) {
    return $http({
      method: 'POST',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/newsletter/',
      data: { newsletter }
    });
  }

  edit (newsletter) {
    return $http({
      method: 'PUT',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/newsletter/' + newsletter.id,
      data: { newsletter }
    });
  }

  delete (newsletterId) {
    return $http({
      method: 'DELETE',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/newsletter/' + newsletterId,
      data: { delete: 'delete' }
    });
  }

  deleteFile (newsletterId) {
    return $http({
      method: 'DELETE',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/newsletter/' + newsletterId + '/file',
      data: { delete: 'delete' }
    });
  }
}

newsletterModelService.$inject = ['$http'];

const newsletterModelModule = angular.module('newsletterModel', [])
  .service('newsletterModel', newsletterModelService)
  .name;

export default newsletterModelModule;
