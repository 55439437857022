import template from './order-payment.html';
import controller from './order-payment.controller';
import './order-payment.scss';

const orderPaymentComponent = {
  require: {
    orderComponent: '^order'
  },
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', 'utils'];

export default orderPaymentComponent;
