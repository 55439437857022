class BookShowController {
  constructor ($rootScope, $state, $sce, bookModel) {
    const vm = this;
    vm.$rootScope = $rootScope;
    vm.$state = $state;
    vm.$sce = $sce;
    vm.bookModel = bookModel;
  }

  $onInit () {
    const vm = this;

    vm.book = vm.book || {};

    vm.bookUrl = vm.$sce.trustAsResourceUrl('/media/books/' + vm.book.number + '.pdf');

    // document.getElementsByTagName('embed')[0].src = vm.bookUrl;
  }

  close () {
    this.$rootScope.$broadcast('modalBoxClose');
  }
}

export default BookShowController;
