let $timeout;

class TapesCategoriesEditController {
  constructor ($rootScope, $state, _$timeout, tapeCategoryModel) {
    const vm = this;
    vm.$rootScope = $rootScope;
    vm.$state = $state;
    $timeout = _$timeout;
    vm.tapeCategoryModel = tapeCategoryModel;
  }

  $onInit () {
    const vm = this;

    vm.tapeCategory = vm.tapeCategory || {};

    vm.modelObject = {
      id: vm.tapeCategory.id || null,
      name: vm.tapeCategory.name,
      description: vm.tapeCategory.description
    };
  }

  save () {
    const vm = this;
    if (vm.tapesCategoriesEditForm.$invalid) {
      vm.tapesCategoriesEditForm.name.$setDirty();
      return console.log('Form is invalid.');
    }

    return vm.tapeCategoryModel[vm.tapeCategory.id ? 'edit' : 'add'](vm.modelObject)
      .then((res) => {
        vm.$rootScope.$broadcast('reload tape categories');
        $timeout(() => {
          vm.close();
        }, 100);
      });
  }

  close () {
    this.$rootScope.$broadcast('modalBoxClose');
  }

  delete () {
    const vm = this;
    if (confirm('האם למחוק קטגוריה?')) {
      return vm.tapeCategoryModel.delete(vm.modelObject.id)
        .then((res) => {
          vm.$rootScope.$broadcast('reload tape categories');
          vm.close();
        });
    }
  }
}

export default TapesCategoriesEditController;
