let $timeout;

class NewsEditController {
  constructor ($rootScope, $state, _$timeout, newsModel) {
    const vm = this;
    vm.$rootScope = $rootScope;
    vm.$state = $state;
    $timeout = _$timeout;
    vm.newsModel = newsModel;

    vm.trumbowygConfig = {
      lang: 'he',
      dir: 'rtl',
      hideButtonTexts: false,
      btns: [
        ['undo', 'redo'], // Only supported in Blink browsers
        ['formatting'],
        ['strong', 'em', 'del'],
        ['superscript', 'subscript'],
        ['justifyRight', 'justifyCenter', 'justifyLeft', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat']
      ]
    };
  }

  $onInit () {
    const vm = this;

    vm.news = vm.news || {};

    vm.modelObject = {
      id: vm.post.id || null,
      title: vm.post.title,
      article: vm.post.article,
      note: vm.post.note,
      isAvailable: (angular.isUndefined(vm.post.isAvailable) ? true : vm.post.isAvailable),
      editor: vm.post.editor
    };
  }

  save () {
    const vm = this;
    if (vm.newsEditForm.$invalid) {
      vm.newsEditForm.title.$setDirty();
      vm.newsEditForm.article.$setDirty();
      return console.log('Form is invalid.');
    }

    return vm.newsModel[vm.post.id ? 'edit' : 'add'](vm.modelObject)
      .then((res) => {
        vm.$rootScope.$broadcast('reload news');
        $timeout(() => {
          vm.close();
        }, 100);
      });
  }

  close () {
    this.$rootScope.$broadcast('modalBoxClose');
  }

  delete () {
    const vm = this;
    if (confirm('האם למחוק כתבה?')) {
      return vm.newsModel.delete(vm.modelObject.id)
        .then((res) => {
          vm.$rootScope.$broadcast('reload news');
          vm.close();
        });
    }
  }
}

export default NewsEditController;
