import template from './newsletter-show.html';
import controller from './newsletter-show.controller';
import './newsletter-show.scss';

const newsletterShowComponent = {
  bindings: {
    newsletter: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$state', '$sce', 'newsletterModel'];

export default newsletterShowComponent;
