import angular from 'angular';
import manageTapeCategoriesComponent from './manage-tape-categories.component';
import TapesCategoriesEdit from './tapes-categories-edit/tapes-categories-edit';

const manageTapeCategoriesModule = angular.module('manageTapeCategories', [
  TapesCategoriesEdit
])
  .component('manageTapeCategories', manageTapeCategoriesComponent)
  .name;

export default manageTapeCategoriesModule;
