function manageNews ($scope, newsModel, googleAnalytics) {
  let vm;
  class ManageNewsController {
    constructor () {
      vm = this;
      vm.loading = true;

      vm.newsListColumns = [
        {
          name: 'number',
          label: 'מזהה'
        },
        {
          name: 'createdAt',
          label: 'תאריך',
          filter: 'date'
        },
        {
          name: 'updatedAt',
          label: 'עודכן לאחרונה',
          filter: 'date'
        },
        {
          name: 'title',
          label: 'כותרת'
        },
        {
          name: 'article',
          label: 'תוכן',
          filter: 'html'
        },
        {
          name: 'note',
          label: 'הערות'
        },
        {
          name: 'editor',
          label: 'עורך'
        },
        {
          name: 'isAvailable',
          label: 'זמין באתר',
          filter: 'boolean'
        }
      ];

      vm.buttons = [
        {
          label: 'כתבה חדשה',
          click: vm.newPost
        }
      ];

      vm.initNewsList();
      $scope.$on('reload news', () => {
        vm.initNewsList();
      });

      googleAnalytics.setPage('ניהול חדשות', 'manage-news');
    }

    initNewsList () {
      newsModel.list()
        .then((res) => {
          vm.newsList = res.data.data;
          vm.loading = false;
        });
    }

    clickOnRow (post) {
      vm.editPost(post);
    }

    newPost () {
      vm.post = {};
      vm.newsModalBoxHeader = 'כתבה חדשה';
      vm.newsModalBoxIsOpen = true;
    }

    editPost (post) {
      vm.post = post;
      vm.newsModalBoxHeader = 'עריכת כתבה';
      vm.newsModalBoxIsOpen = true;
    }
  }
  return new ManageNewsController();
}
export default manageNews;
