function manageTapeCategories ($scope, $timeout, tapeCategoryModel, googleAnalytics) {
  let vm;
  class ManageTapeCategoriesController {
    constructor () {
      vm = this;
      vm.loading = true;
      vm.tapeCategoryListColumns = [
        {
          name: 'number',
          label: 'מזהה'
        },
        {
          name: 'name',
          label: 'שם'
        },
        {
          name: 'note',
          label: 'הערות'
        }
      ];

      vm.buttons = [
        {
          label: 'הוספת קטגוריה',
          click: vm.newTapeCategory
        }
      ];

      vm.initTapeCategoryList();
      $scope.$on('reload tape categories', () => {
        vm.initTapeCategoryList();
      });

      googleAnalytics.setPage('ניהול קטגוריות קלטות', 'manage-tape-categories');
    }

    initTapeCategoryList () {
      tapeCategoryModel.list()
        .then((response) => {
          vm.tapeCategoryList = response.data.data;

          vm.loading = false;
        });
    }

    clickOnRow (tapeCategory) {
      vm.editTapeCategory(tapeCategory);
    }

    newTapeCategory () {
      vm.tapeCategory = {};
      vm.tapeModalBoxHeader = 'הוספת קטגוריה';
      vm.tapeModalBoxIsOpen = true;
    }

    editTapeCategory (tapeCategory) {
      vm.tapeCategory = tapeCategory;
      vm.tapeModalBoxHeader = 'עריכת קטגוריה';
      vm.tapeModalBoxIsOpen = true;
    }
  }
  return new ManageTapeCategoriesController();
}
export default manageTapeCategories;
