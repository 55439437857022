import template from './order.html';
import controller from './order.controller';
import './order.scss';

const orderComponent = {
  bindings: {
    product: '<'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$compile', '$timeout', 'orderModel'];

export default orderComponent;
