import template from './home.html';
import controller from './home.controller';
import './home.scss';

const homeComponent = {
  bindings: {},
  template,
  controllerAs: 'vm',
  controller
};

controller.$inject = ['$rootScope', '$state', 'logInService', 'googleAnalytics'];

export default homeComponent;
