import template from './news-edit.html';
import controller from './news-edit.controller';
import './news-edit.scss';

const newsEditComponent = {
  bindings: {
    post: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$state', '$timeout', 'newsModel'];

export default newsEditComponent;
