import angular from 'angular';

class parshaService {
  constructor () {
    this.parshaList = {
      בראשית: [
        'בראשית', 'נח', 'לך לך', 'וירא', 'חיי שרה', 'תולדות', 'ויצא', 'וישלח', 'וישב', 'מקץ', 'ויגש', 'ויחי'
      ],
      שמות: [
        'שמות', 'וארא', 'בא', 'בשלח', 'יתרו', 'משפטים', 'תרומה', 'תצוה', 'כי תשא', 'ויקהל', 'פקודי'
      ],
      ויקרא: [
        'ויקרא', 'צו', 'שמיני', 'תזריע', 'מצורע', 'אחרי', 'קדושים', 'אמור', 'בהר', 'בחוקותי'
      ],
      במדבר: [
        'במדבר', 'נשא', 'בהעלתך', 'שלח', 'קרח', 'חקת', 'בלק', 'פינחס', 'מטות', 'מסעי'
      ],
      דברים: [
        'דברים', 'ואתחנן', 'עקב', 'ראה', 'שופטים', 'כי תצא', 'כי תבוא', 'ניצבים', 'וילך', 'האזינו', 'וזאת הברכה'
      ],
      'מועדי השנה': [
        'ראש השנה', 'יום כיפור', 'סוכות', 'חנוכה', 'פורים', 'פסח', 'שבועות'
      ]
    };
  }

  getParshaList (humash) {
    return this.parshaList[humash];
  }

  // getParshaListByParsha (parsha) {
  //   let humash = this.parshaList.find((humashName) => this.parshaList[humashName].includes(parsha));
  //   return this.parshaList[humash];
  // }
}

parshaService.$inject = ['$http'];

const parshaServiceModule = angular.module('parshaService', [])
  .service('parshaService', parshaService)
  .name;

export default parshaServiceModule;
