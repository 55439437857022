import angular from 'angular';

import GLOBAL_SETTINGS from '../../global-settings';
const URL = GLOBAL_SETTINGS.HOST + (GLOBAL_SETTINGS.PORT ? ':' + GLOBAL_SETTINGS.PORT : '');

let $http;

class bookModelService {
  constructor (_$http) {
    $http = _$http;
  }

  list (availableOnly) {
    return $http({
      method: 'GET',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/book/' + (availableOnly ? '?availableOnly=true' : '')
    });
  }

  find (id) {
    return $http({
      method: 'GET',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/book/' + id
    });
  }

  add (book) {
    return $http({
      method: 'POST',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/book/',
      data: { book }
    });
  }

  edit (book) {
    return $http({
      method: 'PUT',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/book/' + book.id,
      data: { book }
    });
  }

  delete (bookId) {
    return $http({
      method: 'DELETE',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/book/' + bookId,
      data: { delete: 'delete' }
    });
  }

  deleteFile (bookId) {
    return $http({
      method: 'DELETE',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/book/' + bookId + '/file',
      data: { delete: 'delete' }
    });
  }
}

bookModelService.$inject = ['$http'];

const bookModelModule = angular.module('bookModel', [])
  .service('bookModel', bookModelService)
  .name;

export default bookModelModule;
