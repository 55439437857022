import template from './order-product.html';
import controller from './order-product.controller';
import './order-product.scss';

const orderProductComponent = {
  require: {
    orderComponent: '^order'
  },
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope'];

export default orderProductComponent;
