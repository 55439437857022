import angular from 'angular';
import manageUsersComponent from './manage-users.component';
import UserEdit from './user-edit/user-edit';

const manageUsersModule = angular.module('manageUsers', [
  UserEdit
])
  .component('manageUsers', manageUsersComponent)
  .name;

export default manageUsersModule;
