import angular from 'angular';
import manageOrdersComponent from './manage-orders.component';
import OrderManage from './order-manage/order-manage';

const manageOrdersModule = angular.module('manageOrders', [
  OrderManage
])
  .component('manageOrders', manageOrdersComponent)
  .name;

export default manageOrdersModule;
