import angular from 'angular';
import orderComponent from './order.component';
import orderCustomer from './order-customer/order-customer';
import orderDelivery from './order-delivery/order-delivery';
import orderPayment from './order-payment/order-payment';
import orderProduct from './order-product/order-product';

const orderModule = angular.module('order', [
  orderCustomer,
  orderDelivery,
  orderPayment,
  orderProduct
])
  .component('order', orderComponent)
  .name;

export default orderModule;
