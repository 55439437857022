import template from './newsletters.html';
import controller from './newsletters.controller';
import './newsletters.scss';

const newslettersComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', 'newsletterModel', 'parshaService'];

export default newslettersComponent;
