function orderProduct ($scope) {
  let vm;

  class OrderProductController {
    constructor () {
      vm = this;
      vm.name = 'orderProduct';
    }

    continue () {
      vm.orderComponent.steps[1].isAvailable = true;
      vm.orderComponent.moveToStep(1);
    }
  }

  return new OrderProductController();
}

export default orderProduct;
