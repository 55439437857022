import template from './user-edit.html';
import controller from './user-edit.controller';
import './user-edit.scss';

const userEditComponent = {
  bindings: {
    editPassword: '<?',
    user: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$state', '$timeout', 'userModel'];

export default userEditComponent;
