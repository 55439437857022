let $timeout;

class BooksEditController {
  constructor ($rootScope, $state, _$timeout, bookModel) {
    const vm = this;
    vm.$rootScope = $rootScope;
    vm.$state = $state;
    $timeout = _$timeout;
    vm.bookModel = bookModel;
  }

  $onInit () {
    const vm = this;

    vm.book = vm.book || {};

    vm.modelObject = {
      id: vm.book.id || null,
      name: vm.book.name,
      description: vm.book.description,
      bookCategoryId: vm.book.bookCategoryId || null,
      price: vm.book.price || 0,
      link: vm.book.link,
      isAvailable: (angular.isUndefined(vm.book.isAvailable) ? true : vm.book.isAvailable),
      forSale: (angular.isUndefined(vm.book.forSale) ? true : vm.book.forSale),
      note: vm.book.note,
      pages: vm.book.pages
    };
  }

  save () {
    const vm = this;
    if (vm.booksEditForm.$invalid) {
      vm.booksEditForm.name.$setDirty();
      vm.booksEditForm.price.$setDirty();
      return console.log('Form is invalid.');
    }

    return vm.bookModel[vm.book.id ? 'edit' : 'add'](vm.modelObject)
      .then((res) => {
        vm.$rootScope.$broadcast('reload books');
        $timeout(() => {
          vm.close();
        }, 100);
      });
  }

  close () {
    this.$rootScope.$broadcast('modalBoxClose');
  }

  delete () {
    const vm = this;
    if (confirm('האם למחוק ספר?')) {
      return vm.bookModel.delete(vm.modelObject.id)
        .then((res) => {
          vm.$rootScope.$broadcast('reload books');
          vm.close();
        });
    }
  }
}

export default BooksEditController;
