function news ($scope, $timeout, newsModel) {
  let vm;
  class NewsController {
    constructor () {
      vm = this;
      vm.loading = true;
      vm.name = 'news';
      vm.label = 'חדשות בן מלך';

      vm.initNewsList();
      $scope.$on('reload news', () => {
        vm.initNewsList();
      });
    }

    initNewsList () {
      newsModel.list()
        .then((res) => {
          vm.newsList = res.data.data;
          vm.newsList = vm.newsList.filter((post) => {
            return post.isAvailable === true;
          });
          vm.newsListLength = vm.newsList.length;
          vm.loading = false;
          $timeout(() => {
            vm.setAnimation();
          }, 10);
        });
    }

    setAnimation () {
      const newsListContainer = document.querySelector('.news-list.scroll .news-list-container');
      const newsListContainer2 = document.querySelector('.news-list.scroll .news-list-container2');
      const duration = vm.newsListLength * 31;
      const delay = duration / 2;

      newsListContainer.style.WebkitAnimation = `scrollText ${duration}s linear 0s infinite`;
      newsListContainer2.style.WebkitAnimation = `scrollText ${duration}s linear -${delay}s infinite`;
      newsListContainer.style.animation = `scrollText ${duration}s linear 0s infinite`;
      newsListContainer2.style.animation = `scrollText ${duration}s linear -${delay}s infinite`;
    }
  }
  return new NewsController();
}
export default news;
