import angular from 'angular';
import GLOBAL_SETTINGS from '../../global-settings';

let $window;

class googleAnalyticsService {
  constructor (_$window) {
    $window = _$window;
  }

  setPage (title, location) {
    location = location || '';
    $window.document.title = 'בן מלך' + (title ? ' - ' + title : '');
    $window.gtag('config', GLOBAL_SETTINGS.GA_TRACKING_ID, { page_path: '/' + location });
  }
}

googleAnalyticsService.$inject = ['$window'];

const googleAnalyticsModule = angular.module('googleAnalytics', [])
  .service('googleAnalytics', googleAnalyticsService)
  .name;

export default googleAnalyticsModule;
