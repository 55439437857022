class Utils {
  constructor () {
    this.errorMessages = {
      required: 'שדה חובה',
      minlength: 'ערך קצר מידי',
      maxlength: 'ערך ארוך מידי',
      pattern: 'ערך לא תקין',
      email: 'דוא"ל לא תקין',
      min: 'מספר נמוך מידי',
      max: 'מספר גבוה מידי',
      number: 'יש להזין ספרות בלבד',
      phoneIsRequired: 'יש למלא לפחות מספר טלפון תקין אחד'
    };
  }

  setFormFieldsDirty (formCtrl) {
    if (!formCtrl.$$controls) {
      return;
    }

    for (const field of formCtrl.$$controls) {
      if (field.$setDirty) {
        field.$setDirty();
      }
    }
  }
}

const utilsModule = angular.module('utils', [])
  .service('utils', Utils)
  .name;

export default utilsModule;
