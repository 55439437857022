import template from './manage-news.html';
import controller from './manage-news.controller';
import './manage-news.scss';

const manageNewsComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', 'newsModel', 'googleAnalytics'];

export default manageNewsComponent;
