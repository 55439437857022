function tapes ($scope, tapeModel) {
  let vm;

  class TapesController {
    constructor () {
      vm = this;
      vm.loading = true;
      vm.name = 'tapes';
      vm.label = 'אוצר הקלטות';

      vm.orderBy = 'name';
      vm.initTapeList();
      $scope.$on('reload tapes', () => {
        vm.initTapeList();
      });
    }

    sort (value) {
      if (vm.orderBy === value) {
        vm.orderBy = vm.togglePlusMinus(vm.orderBy);
      } else {
        vm.orderBy = value;
      }
    }

    togglePlusMinus (value) {
      if (value[0] === '-') {
        value = value.replace('-', '');
      } else {
        value = '-' + value;
      }
      return value;
    }

    initTapeList () {
      tapeModel.list(true)
        .then((res) => {
          vm.tapeList = res.data.data;
          vm.typeList = [];
          vm.tapeList.forEach((item) => {
            if (!vm.typeList.includes(item.type)) {
              vm.typeList.push(item.type);
            }
            item.yearForSorting = item.year ? item.year.replace(/"/g, '') : '';
          });
          vm.loading = false;
        });
    }

    openDescription (tape) {
      if (!tape.description.length) {
        return;
      }
      vm.tapeModalBoxHeader = tape.name;
      vm.tapeModalBoxStyle = 'compact';
      vm.tape = tape;
      vm.closeOutside = true;
      vm.tapeModalBoxIsOpen = true;
      vm.tapeModalBoxContent = `<div class="tape-description">
        <div class="tape-description-header">תוכן ענינים</div>
        <div class="tape-description-content">${tape.description}</div>
      </div>`;
    }
  }
  return new TapesController();
}
export default tapes;
