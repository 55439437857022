class PageController {
  constructor (googleAnalytics) {
    const vm = this;
    vm.name = 'page';
    vm.googleAnalytics = googleAnalytics;

    vm.pageList = {
      books: 'אוצר הספרים',
      tapes: 'אוצר הקלטות',
      news: 'חדשות בן מלך'
    };
  }

  $onInit () {
    const vm = this;
    vm.googleAnalytics.setPage(vm.pageList[vm.page], vm.page);
  }
}

export default PageController;
