import template from './list.html';
import controller from './list.controller';
import './list.scss';

const listComponent = {
  bindings: {
    name: '@',
    title: '@',
    columns: '<',
    list: '<',
    orderBy: '<?',
    loading: '<?',
    click: '<?',
    buttons: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

// controller.$inject = [''];

export default listComponent;
