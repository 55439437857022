import angular from 'angular';
import manageNewslettersComponent from './manage-newsletters.component';
import newslettersEdit from './newsletters-edit/newsletters-edit';

const manageNewslettersModule = angular.module('manageNewsletters', [
  newslettersEdit
])
  .component('manageNewsletters', manageNewslettersComponent)
  .name;

export default manageNewslettersModule;
