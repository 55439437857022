import GLOBAL_SETTINGS from '../../../global-settings';
const URL = GLOBAL_SETTINGS.HOST + (GLOBAL_SETTINGS.PORT ? ':' + GLOBAL_SETTINGS.PORT : '');

function manageBook ($scope, $timeout, bookModel, FileUploader, googleAnalytics) {
  let vm;
  class ManageBookController {
    constructor () {
      vm = this;
      vm.loading = true;
      vm.bookListColumns = [
        {
          name: 'number',
          label: 'מזהה'
        },
        {
          name: 'name',
          label: 'שם'
        },
        {
          name: 'category',
          label: 'קטגוריה'
        },
        {
          name: 'description',
          label: 'תיאור'
        },
        {
          name: 'price',
          label: 'מחיר'
        },
        {
          name: 'isAvailable',
          label: 'זמין באתר',
          filter: 'boolean'
        },
        {
          name: 'forSale',
          label: 'זמין למכירה',
          filter: 'boolean'
        },
        {
          name: 'note',
          label: 'הערות'
        },
        {
          name: 'fileButton',
          filter: 'button'
        }
      ];

      vm.buttons = [
        {
          label: 'הוספת ספר',
          click: vm.newBook
        }
      ];

      vm.initBookList();
      $scope.$on('reload books', () => {
        vm.initBookList();
      });

      googleAnalytics.setPage('ניהול ספרים', 'manage-books');

      vm.setupFileUploader();
    }

    initBookList () {
      bookModel.list()
        .then((response) => {
          vm.bookList = response.data.data;
          vm.bookList.forEach((item) => {
            if (item.bookCategory && item.bookCategory.name) {
              item.category = item.bookCategory.name;
            }
            if (item.fileExist) {
              item.fileButton = {
                text: '<i class="fa fa-fw fa-trash"></i> מחיקת קובץ',
                click: vm.deleteBookFileButton
              };
            } else {
              item.fileButton = {
                text: '<i class="fa fa-fw fa-upload"></i> העלאת קובץ',
                click: vm.uploadBookFileButton
              };
            }
          });

          vm.loading = false;
        });
    }

    clickOnRow (book) {
      vm.editBook(book);
    }

    newBook () {
      vm.book = {};
      vm.bookModalBoxHeader = 'הוספת ספר';
      vm.bookModalBoxIsOpen = true;
    }

    editBook (book) {
      vm.book = book;
      vm.bookModalBoxHeader = 'עריכת ספר';
      vm.bookModalBoxIsOpen = true;
    }

    deleteBookFileButton (book, event) {
      if (confirm('האם למחוק קובץ ספר?')) {
        bookModel.deleteFile(book.id)
          .then((response) => {
            console.log('response:', response);
            vm.initBookList();
          });
      }
    }

    uploadBookFileButton (book, event) {
      vm.book = book;
      vm.bookFileModalBoxHeader = 'העלאת קובץ ספר';
      vm.uploaderOptions.url = URL + '/api/book/' + book.id + '/upload';
      vm.bookFileModalBoxIsOpen = true;
    }

    setupFileUploader () {
      vm.uploader = new FileUploader();
      vm.uploaderOptions = {
        url: '',
        queueLimit: 1
      };
      vm.uploader.onAfterAddingFile = function (item) {
        vm.uploader.queue = [vm.uploader.queue[vm.uploader.queue.length - 1]];
        vm.fileUploadProgress = 0;
      };
      vm.uploader.onBeforeUploadItem = function (item) {
        vm.fileUploadProgress = 0;
      };
      vm.uploader.onProgressItem = function (item, progress) {
        vm.fileUploadProgress = progress;
      };
      vm.uploader.onSuccessItem = function (item, response, status, headers) {
        vm.uploader.queue = [];
        vm.fileUploadProgress = 0;
        vm.fileUploadMessage = 'הקובץ הועלה בהצלחה';
        console.log('response:', response);
        vm.initBookList();

        $timeout(() => {
          vm.fileUploadMessage = '';
          vm.bookFileModalBoxIsOpen = false;
        }, 1000);
      };
      vm.uploader.onErrorItem = function (item, response, status, headers) {
        vm.uploader.queue = [];
        vm.fileUploadProgress = 0;
        vm.fileUploadMessage = 'שגיאה בהעלאת קובץ';
        $timeout(() => {
          vm.fileUploadMessage = '';
          vm.bookFileModalBoxIsOpen = false;
        }, 2000);
      };
    }
  }
  return new ManageBookController();
}
export default manageBook;
