import angular from 'angular';

import GLOBAL_SETTINGS from '../../global-settings';
const URL = GLOBAL_SETTINGS.HOST + (GLOBAL_SETTINGS.PORT ? ':' + GLOBAL_SETTINGS.PORT : '');

let $http;

class tapeCategoryModelService {
  constructor (_$http) {
    $http = _$http;
  }

  list (availableOnly) {
    return $http({
      method: 'GET',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/tape-category/' + (availableOnly ? '?availableOnly=true' : '')
    });
  }

  find (id) {
    return $http({
      method: 'GET',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/tape-category/' + id
    });
  }

  add (tapeCategory) {
    return $http({
      method: 'POST',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/tape-category/',
      data: { tapeCategory }
    });
  }

  edit (tapeCategory) {
    return $http({
      method: 'PUT',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/tape-category/' + tapeCategory.id,
      data: { tapeCategory }
    });
  }

  delete (tapeCategoryId) {
    return $http({
      method: 'DELETE',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      url: URL + '/api/tape-category/' + tapeCategoryId,
      data: { delete: 'delete' }
    });
  }
}

tapeCategoryModelService.$inject = ['$http'];

const tapeCategoryModelModule = angular.module('tapeCategoryModel', [])
  .service('tapeCategoryModel', tapeCategoryModelService)
  .name;

export default tapeCategoryModelModule;
